import { IPracticeParams, IPracticeResponse } from '.';
import { BaseService } from '../baseService';

class PracticeService extends BaseService<any, IPracticeResponse> {
    constructor() {
        super('/practices');
    }

    async getPractice(id: string) {
        const response = await this.getById(id);
        return response;
    }
    async getPractices(data: IPracticeParams) {
        const response = await this.get(data);
        return response;
    }
    async getFilteredPractices(data: any) {
        const response = await this.get(data);
        return response;
    }
    async getPracticesWithSections(data: IPracticeParams) {
        const response = await this.get(data, '/with-sections');
        return response;
    }
    async deletePractice(id: string) {
        const response = await this.delete(id);
        return response;
    }
    async createPractice(data: any) {
        const response = await this.create(data);
        return response;
    }
    async updatePractice(data: any) {
        const response = await this.update(data.id, data.body);
        return response;
    }
}

export default new PracticeService();

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import groupService from '../../services/api/api-services/groupService';

interface GroupState {
    selectedGroup: any;
    loading: boolean;
    error: string | null;
}

const initialState: GroupState = {
    selectedGroup: null,
    loading: false,
    error: null,
};

export const fetchGroupById = createAsyncThunk('group/fetchById', async (id: string) => {
    const response = await groupService.getGroupById(id);
    return response.result;
});

const groupSlice = createSlice({
    name: 'group',
    initialState,
    reducers: {
        clearSelectedGroup: (state) => {
            state.selectedGroup = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchGroupById.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchGroupById.fulfilled, (state, action) => {
                state.selectedGroup = action.payload;
                state.loading = false;
            })
            .addCase(fetchGroupById.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message || 'Failed to fetch group';
            });
    },
});

export const { clearSelectedGroup } = groupSlice.actions;
export default groupSlice.reducer;

import { Checkbox, Col, Form, Row } from 'antd';
export const RoleCheckbox = () => {
    return (
        <Row>
            <Col span={8}>
                <Form.Item name="userCreate" valuePropName="checked" noStyle>
                    <Checkbox>User Create</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="user" valuePropName="checked" noStyle>
                    <Checkbox>User</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="userUpdate" valuePropName="checked" noStyle>
                    <Checkbox>User Update</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="userDelete" valuePropName="checked" noStyle>
                    <Checkbox>User Delete</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="practice" valuePropName="checked" noStyle>
                    <Checkbox>Practice</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="practiceAll" valuePropName="checked" noStyle>
                    <Checkbox>Practice All</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="practiceCreate" valuePropName="checked" noStyle>
                    <Checkbox>Practice Create</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="practiceUpdate" valuePropName="checked" noStyle>
                    <Checkbox>Practice Update</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="practiceDelete" valuePropName="checked" noStyle>
                    <Checkbox>Practice Delete</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="practiceResult" valuePropName="checked" noStyle>
                    <Checkbox>Practice Result</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="group" valuePropName="checked" noStyle>
                    <Checkbox>Group</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="groupCreate" valuePropName="checked" noStyle>
                    <Checkbox>Group Create</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="groupUpdate" valuePropName="checked" noStyle>
                    <Checkbox>Group Update</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="groupDelete" valuePropName="checked" noStyle>
                    <Checkbox>Group Delete</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="admin" valuePropName="checked" noStyle>
                    <Checkbox>Admin</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="adminCreate" valuePropName="checked" noStyle>
                    <Checkbox>Admin Create</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="adminUpdate" valuePropName="checked" noStyle>
                    <Checkbox>Admin Update</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="adminDelete" valuePropName="checked" noStyle>
                    <Checkbox>Admin Delete</Checkbox>
                </Form.Item>
            </Col>

            <Col span={8}>
                <Form.Item name="role" valuePropName="checked" noStyle>
                    <Checkbox>Role</Checkbox>
                </Form.Item>
            </Col>
        </Row>
    );
};

import { NavigateFunction } from 'react-router-dom';
import { UserOutlined, RobotFilled, ClusterOutlined, HomeOutlined, GroupOutlined } from '@ant-design/icons';

export const LocalizationData = {
    components: {
        mainLayout: {
            navbar: {
                headTitle: 'Admin panel',
            },
            sidebar: {
                menuList: (navigate: NavigateFunction) => [
                    {
                        key: '1',
                        icon: <HomeOutlined />,
                        label: 'Dashboard',
                        onClick: () => navigate('/dashboard'),
                    },
                    {
                        key: '2',
                        icon: <UserOutlined />,
                        label: 'Users',
                        onClick: () => navigate('/users'),
                    },
                    {
                        key: '3',
                        icon: <ClusterOutlined />,
                        label: 'Practices',
                        onClick: () => navigate('/practices'),
                    },
                    {
                        key: '4',
                        icon: <GroupOutlined />,
                        label: 'Group',
                        onClick: () => navigate('/group'),
                    },
                    {
                        key: '5',
                        icon: <RobotFilled />,
                        label: 'Role',
                        onClick: () => navigate('/role'),
                    },
                ],
            },
        },
    },
    pages: {
        users: {},
    },
};

import { Button } from 'antd';
import { IPracticeTableDataConfig } from '../../practices';
import PopConfirmApp from '../../../components/button/PopConfirmApp';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import roleService from '../../../services/api/api-services/roleService';

export interface RoleValues {
    name: string;
    userCreate: string;
    user: boolean;
    userUpdate: boolean;
    userDelete: boolean;
    practice: boolean;
    practiceAll: boolean;
    practiceCreate: boolean;
    practiceUpdate: boolean;
    practiceDelete: boolean;
    practiceResult: boolean;
    group: boolean;
    groupCreate: boolean;
    groupUpdate: boolean;
    groupDelete: boolean;
    admin: boolean;
    adminCreate: boolean;
    adminUpdate: boolean;
    adminDelete: boolean;
    role: boolean;
    _id: string;
    permissions?: string[];
}
export const useGetRoleCols = (handleEdit: (id: string) => void, handleDelete: (id: string) => void) => {
    return [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 800,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (_: unknown, record: IPracticeTableDataConfig) => (
                <div className="d-flex-even">
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record._id)}></Button>
                    <PopConfirmApp
                        title="Are you sure you want to delete this role?"
                        description="This action cannot be undone."
                        confirm={() => handleDelete(record?._id)}
                        children={<Button icon={<DeleteOutlined />} danger></Button>}
                    />
                </div>
            ),
            width: 200,
        },
    ];
};

export async function getRoles(currentPage: number = 0, pageSize: number = 10, search: string) {
    const api = roleService;
    let result;
    if (search) {
        result = api.getRoles({ page: currentPage, limit: pageSize, search: search });
    } else {
        result = api.getRoles({ page: currentPage, limit: pageSize });
    }
    return result.then((response) => response);
}

export const useGetRoleValues = (values: RoleValues) => {
    return {
        name: values.name ?? '',
        userCreate: values.userCreate ?? false,
        user: values.user ?? false,
        userUpdate: values.userUpdate ?? false,
        userDelete: values.userDelete ?? false,
        practice: values.practice ?? false,
        practiceAll: values.practiceAll ?? false,
        practiceCreate: values.practiceCreate ?? false,
        practiceUpdate: values.practiceUpdate ?? false,
        practiceDelete: values.practiceDelete ?? false,
        practiceResult: values.practiceResult ?? false,
        group: values.group ?? false,
        groupCreate: values.groupCreate ?? false,
        groupUpdate: values.groupUpdate ?? false,
        groupDelete: values.groupDelete ?? false,
        admin: values.admin ?? false,
        adminCreate: values.adminCreate ?? false,
        adminUpdate: values.userUpdate ?? false,
        adminDelete: values.adminDelete ?? false,
        role: values.role ?? false,
    };
};

import { Button } from 'antd';
import { IPracticeTableDataConfig } from '../../practices';
import PopConfirmApp from '../../../components/button/PopConfirmApp';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import groupService from '../../../services/api/api-services/groupService';

export const useGetCols = (handleEdit: (id: string) => void, handleDelete: (id: string) => void) => {
    return [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            width: 800,
        },
        {
            title: 'Users',
            dataIndex: 'userIds.length',
            key: 'userIds.length',
            render: (_: any, rec: any) => rec.userIds.length || 0,
            width: 400,
        },
        {
            key: 'actions',
            title: 'Actions',
            render: (_: unknown, record: IPracticeTableDataConfig) => (
                <div className="d-flex-even">
                    <Button icon={<EditOutlined />} onClick={() => handleEdit(record._id)}></Button>
                    <PopConfirmApp
                        title="Are you sure you want to delete this group?"
                        description="This action cannot be undone."
                        confirm={() => handleDelete(record?._id)}
                        children={<Button icon={<DeleteOutlined />} danger></Button>}
                    />
                </div>
            ),
            width: 200,
        },
    ];
};

export async function getGroups(currentPage: number = 0, pageSize: number = 10, search?: string) {
    const api = groupService;
    let result;
    if (search) {
        result = api.getGroups({ page: currentPage, limit: pageSize, search: search });
    } else {
        result = api.getGroups({ page: currentPage, limit: pageSize });
    }
    return result.then((response) => response);
}

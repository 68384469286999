import { message } from 'antd';

export const useMessage = () => {
    const [messageApi, ctxHolder] = message.useMessage();
    async function onSuccess(message: string) {
        messageApi.open({
            type: 'success',
            content: message,
        });
    }

    async function onError(message: string) {
        messageApi.open({
            type: 'error',
            content: message,
        });
    }
    async function onWarning(message: string) {
        messageApi.open({
            type: 'warning',
            content: message,
        });
    }
    return {
        ctxHolder,
        onSuccess,
        onError,
        onWarning,
    };
};

import { Checkbox, Col, Form, Input, Modal, Row } from 'antd';
import { useEffect } from 'react';
import roleService from '../../../services/api/api-services/roleService';
import { useMessage } from '../../../hooks/useMassage';
import axios from 'axios';
import { RoleCheckbox } from './RoleCheckbox';
import { RoleValues, useGetRoleValues } from '../helpers';

interface RoleModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    editingRole: RoleValues;
}

export const RolesModal = ({ isOpen, onClose, onSuccess, editingRole }: RoleModalProps) => {
    const [form] = Form.useForm<RoleValues>();
    const api = roleService;
    const { ctxHolder, onError, onSuccess: onSuccessMessage } = useMessage();

    function updateRoleById(id: string, data: any) {
        return api.updateRole(id, data);
    }
    useEffect(() => {
        if (editingRole) {
            form.setFieldsValue(useGetRoleValues(editingRole));
        } else {
            form.resetFields();
        }
    }, [editingRole, form]);
    const onSubmit = async () => {
        try {
            const values = await form.validateFields();
            const result = useGetRoleValues(values);
            const hasSelectedCheckbox = Object.entries(result)
                .filter(([key]) => key !== 'name')
                .some(([_, value]) => value === true);

            if (!hasSelectedCheckbox) {
                form.setFields([
                    {
                        name: 'permissions',
                        errors: ['Please select at least one option'],
                    },
                ]);
                return;
            }

            if (editingRole) {
                await updateRoleById(editingRole._id, result);
                onSuccessMessage('The role has been successfully updated.');
            } else {
                await api.createRole(result);
                onSuccessMessage('The role has been successfully created.');
            }
            onSuccess();
            form.resetFields();
            onClose();
        } catch (error: unknown) {
            if (axios.isAxiosError(error)) {
                onError(error.message);
            }
        }
    };

    return (
        <>
            {ctxHolder}
            <Modal
                title={editingRole ? 'Edit Role' : 'Create Role'}
                open={isOpen}
                onCancel={() => {
                    form.resetFields();
                    onClose();
                }}
                onOk={onSubmit}
            >
                <Form form={form} layout="vertical">
                    <Form.Item
                        name="name"
                        label="Role Name"
                        rules={[{ required: true, message: 'Please input role name!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item name="permissions" label="Permissions" help="Please select at least one option">
                        <RoleCheckbox />
                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
};

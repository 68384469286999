import { Popconfirm, Tooltip } from 'antd';
import { IConfirmProps } from '.';

export default function PopConfirmApp(props: IConfirmProps) {
    const { children, confirm, id, tooltip, title, description } = props;

    return (
        <Tooltip title={tooltip} trigger={'hover'}>
            <Popconfirm
                okText="Yes"
                id={id ?? ''}
                cancelText="No"
                onCancel={() => {}}
                title={title ?? "Ushbu taskni o'chirish"}
                onConfirm={confirm}
                description={description ?? "Ushbu task haqiqatdan ham o'chirilsinmi?"}
            >
                {children}
            </Popconfirm>
        </Tooltip>
    );
}

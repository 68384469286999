import { useEffect, useState } from 'react';

export const useDebounce = (value: string, delay: number): string => {
    const [txt, setTxt] = useState('');
    useEffect(() => {
        const timer = setTimeout(() => {
            setTxt(value);
        }, delay);
        return () => {
            clearTimeout(timer);
        };
    }, [value, delay]);
    return txt;
};

import { Drawer, Layout, Menu } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from '../../../app/hooks';
import { LocalizationData } from '../../../utils/helpers';

const { Sider } = Layout;
export default function Sidebar(props: ISidebarProps) {
    const navigate = useNavigate();
    const { isMobile } = useAppSelector((state) => state.global);

    const menuItems = LocalizationData.components.mainLayout.sidebar.menuList(navigate);
    return (
        <>
            {!isMobile && (
                <Sider trigger={null} collapsible collapsed={props.collapsed} theme="light">
                    <Menu
                        theme="light"
                        mode="inline"
                        className="left-menu"
                        defaultSelectedKeys={[sessionStorage.getItem('key') ?? '0']}
                        onSelect={(e: any) => sessionStorage.setItem('key', e?.key)}
                        items={menuItems}
                    />
                </Sider>
            )}
            {isMobile && (
                <Drawer
                    title="Close"
                    className="resp-drawer"
                    placement={'left'}
                    closable={true}
                    onClose={props.onClose}
                    open={!props.collapsed}
                    key={'left'}
                    size="default"
                >
                    <Menu
                        theme="light"
                        mode="inline"
                        className="left-mobile-menu"
                        items={menuItems}
                        defaultSelectedKeys={[sessionStorage.getItem('key') ?? '0']}
                        onSelect={(e: any) => sessionStorage.setItem('key', e?.key)}
                    />
                </Drawer>
            )}
        </>
    );
}

import {
    ANY_SECTIONS,
    CLONE_TO_LEFT,
    CLONE_TO_RIGHT,
    CREATE_PRACTICE,
    CREATE_SECTION,
    CREATE_USER,
    CURRENT_SECTIONS,
    DELETE_PRACTICE,
    DELETE_SECTION,
    DELETE_USER,
    GET_FILTERED_PRACTICES,
    GET_PRACTICE,
    GET_PRACTICES,
    GET_TOKEN,
    GET_USERS,
    UPDATE_PRACTICE,
    UPDATE_SECTION,
    UPDATE_USER,
    WITH_SECTIONS,
} from './types';
import {
    ILogin,
    IPracticeParams,
    IPracticeResponse,
    IUser,
    IUserParams,
    IUserResponse,
} from '../../services/api/api-services';
import { put, takeEvery } from 'redux-saga/effects';
import {
    setAnySectionsById,
    setAuth,
    setFilteredPractices,
    setLoaders,
    setLogged,
    setNewPractice,
    setNewUser,
    setNotification,
    setPractice,
    setPracticeWithSections,
    setPracticeWithSectionsDublicate,
    setPractices,
    setSection,
    setSectionsById,
    setUpdateSection,
    setUsers,
} from '..';

import { PayloadAction } from '@reduxjs/toolkit';
import PracticeService from '../../services/api/api-services/practiceService';
import SectionService from '../../services/api/api-services/sectionService';
import UserService from '../../services/api/api-services/userService';

function* _createUser({ payload }: PayloadAction<IUser>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.addUser(payload);
        yield put(setNewUser(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _updateUser({ payload }: PayloadAction<{ id: string; body: IUser }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.update(payload.id, payload.body);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _deleteUser({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.delete(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _createPractice({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.createPractice(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNewPractice(response));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _updatePractice({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.updatePractice(payload);

        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _deletePractice({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.delete(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _cloneToLeftSections({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.postClone(payload);
        const responseW: IPracticeResponse = yield SectionService.getSectionsById(payload.toPracticeId);
        yield put(setSectionsById(responseW?.result));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
        console.error('clone response error => ', error);
    }
}
function* _cloneToRightSections({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.postClone(payload);

        const responseP: IPracticeResponse = yield PracticeService.getPracticesWithSections({
            search: '',
        });
        const structured = responseP?.result?.data?.map((practice: any) => ({
            _id: practice?._id,
            name: practice?.name,
            sections: practice?.sections,
        }));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPracticeWithSections(structured));
        yield put(setPracticeWithSectionsDublicate(structured));
        yield put(setAnySectionsById(structured?.filter((x: any) => x?._id == payload?.toPracticeId)[0]?.sections));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _updateSection({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.updateSection(payload);

        yield put(setUpdateSection(response));
        const responseSec: IPracticeResponse = yield SectionService.getSectionsById(
            String(window.location.href.split('?id=').pop())
        );

        yield put(setSectionsById(responseSec?.result));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _deleteSection({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.deleteSection(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _createSection({ payload }: PayloadAction<any>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.createSection(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setSection(response?.result));
        yield put(setNotification({ type: 'success' }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}

// not set notifications (set only error notif.)
function* _logIn({ payload }: PayloadAction<ILogin>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.login(payload);
        yield put(setAuth(response?.result?.token));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setLogged(true));
        sessionStorage.setItem('auth', response?.result?.token);
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setLogged(false));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _getUsers({ payload }: PayloadAction<IUserParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IUserResponse = yield UserService.getUsers(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setUsers(response?.result?.data));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _getPractices({ payload }: PayloadAction<IPracticeParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.getPractices(payload);
        sessionStorage.setItem('totalPractices', response?.result?.total);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPractices(response?.result?.data));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _getFilteredPractices({ payload }: PayloadAction<IPracticeParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.getFilteredPractices(payload);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setFilteredPractices(response?.result?.data));
        sessionStorage.setItem('totalPractices', response?.result?.total);
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _getPractice({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.getPractice(payload.id);
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPractice(response?.result));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        yield put(
            setNotification({
                type: 'error',
                content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
            })
        );
    }
}
function* _getPracticesWithSections({ payload }: PayloadAction<IPracticeParams>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield PracticeService.getPracticesWithSections(payload);
        const structured = response?.result?.data?.map((practice: any) => ({
            _id: practice?._id,
            name: practice?.name,
            sections: practice?.sections,
        }));
        yield put(setLoaders({ layoutLoad: false }));
        yield put(setPracticeWithSections(structured));
        yield put(setPracticeWithSectionsDublicate(structured));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        setNotification({
            type: 'error',
            content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
        });
    }
}
function* _getSectionsById({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.getSectionsById(payload.id);
        yield put(setSectionsById(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        setNotification({
            type: 'error',
            content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
        });
    }
}
function* _getAnySectionsById({ payload }: PayloadAction<{ id: string }>) {
    yield put(setLoaders({ layoutLoad: true }));
    try {
        const response: IPracticeResponse = yield SectionService.getSectionsById(payload.id);
        yield put(setAnySectionsById(response?.result));
        yield put(setLoaders({ layoutLoad: false }));
    } catch (error: any) {
        yield put(setLoaders({ layoutLoad: false }));
        setNotification({
            type: 'error',
            content: error?.response?.data?.message ?? 'Xatolik yuz berdi!',
        });
    }
}

function* mySaga() {
    yield takeEvery(GET_TOKEN, _logIn);
    yield takeEvery(GET_USERS, _getUsers);
    yield takeEvery(CREATE_USER, _createUser);
    yield takeEvery(UPDATE_USER, _updateUser);
    yield takeEvery(DELETE_USER, _deleteUser);
    yield takeEvery(GET_PRACTICE, _getPractice);
    yield takeEvery(GET_PRACTICES, _getPractices);
    yield takeEvery(GET_FILTERED_PRACTICES, _getFilteredPractices);
    yield takeEvery(WITH_SECTIONS, _getPracticesWithSections);
    yield takeEvery(CREATE_PRACTICE, _createPractice);
    yield takeEvery(UPDATE_PRACTICE, _updatePractice);
    yield takeEvery(DELETE_PRACTICE, _deletePractice);
    yield takeEvery(CREATE_SECTION, _createSection);
    yield takeEvery(UPDATE_SECTION, _updateSection);
    yield takeEvery(DELETE_SECTION, _deleteSection);
    yield takeEvery(CLONE_TO_LEFT, _cloneToLeftSections);
    yield takeEvery(CLONE_TO_RIGHT, _cloneToRightSections);
    yield takeEvery(CURRENT_SECTIONS, _getSectionsById);
    yield takeEvery(ANY_SECTIONS, _getAnySectionsById);
}
export default mySaga;

import { Form, Input, Modal, Select } from 'antd';
import { useEffect, useState } from 'react';
import groupService from '../../../services/api/api-services/groupService';
import userService from '../../../services/api/api-services/userService';

interface GroupModalProps {
    isOpen: boolean;
    onClose: () => void;
    onSuccess: () => void;
    editingGroup?: { _id: string; name: string; userIds: string[]; users: { name: string; _id: string }[] };
}

interface User {
    _id: string;
    name: string;
    phone: string;
}

interface FormValues {
    name: string;
    userIds: string[];
}

export const GroupModal = ({ isOpen, onClose, editingGroup, onSuccess }: GroupModalProps) => {
    const [form] = Form.useForm<FormValues>();
    const [users, setUsers] = useState<User[]>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (editingGroup) {
            form.setFieldsValue({
                name: editingGroup.name,
                userIds: editingGroup.users.map((user) => user._id),
            });

            fetchUsers();
        } else {
            form.resetFields();
        }
    }, [editingGroup, form]);

    function updateGroupById(id: string, data: any) {
        return api.updateGroup(id, data);
    }

    const api = groupService;
    const onSubmit = async () => {
        try {
            const values = await form.validateFields();
            if (editingGroup) {
                await updateGroupById(editingGroup._id, values);
            } else {
                await api.createGroup(values);
            }
            onSuccess();
            form.resetFields();
            onClose();
        } catch (error) {
            console.log(error);
        }
    };

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const response = await userService.getUsers({ limit: 5000, page: 1 });
            setUsers(response.result.data);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            title={`${editingGroup ? 'Edit' : 'Create'} Practice Group`}
            open={isOpen}
            onCancel={() => {
                form.resetFields();
                onClose();
            }}
            onOk={onSubmit}
            confirmLoading={loading}
        >
            <Form form={form} layout="vertical">
                <Form.Item
                    name="name"
                    label="Group Name"
                    rules={[{ required: true, message: 'Please input group name!' }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    name="userIds"
                    label="Users"
                    rules={[{ required: true, message: 'Please select at least one user!' }]}
                >
                    <Select
                        mode="multiple"
                        allowClear
                        style={{ width: '100%' }}
                        placeholder={loading ? 'Loading...' : 'Please select users'}
                        onFocus={fetchUsers}
                        loading={loading}
                        optionFilterProp="label"
                        showSearch
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={users.map((user) => ({
                            label: `${user.name} ${user.phone ? user.phone : ''}`,
                            value: user._id,
                        }))}
                    />
                </Form.Item>
            </Form>
        </Modal>
    );
};

import { IGroupParams, IGroupsResponse, IRoleParams } from '.';
import { BaseService } from '../baseService';

class RoleService extends BaseService<any, IGroupsResponse> {
    constructor() {
        super('/roles');
    }

    async getRoles(data: IRoleParams) {
        const response = await this.get(data);
        return response;
    }
    async deleteRole(id: string) {
        const response = await this.delete(id);
        return response;
    }
    async createRole(data: any) {
        const response = await this.create(data);
        return response;
    }
    async getRoleById(id: string) {
        const response = await this.getById(id);
        return response;
    }
    async updateRole(id: string, data: any) {
        const response = await this.update(id, data);
        return response;
    }
}

export default new RoleService();

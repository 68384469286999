import { FileAddOutlined } from '@ant-design/icons';
import { Button, Input, Pagination, PaginationProps, Table } from 'antd';
import { useEffect, useState } from 'react';
import roleService from '../../services/api/api-services/roleService';
import { RolesModal } from './components/RolesModal';
import { getRoles, useGetRoleCols } from './helpers';
import { useMessage } from '../../hooks/useMassage';
import { useDebounce } from '../../hooks/useDebounce';

export const Role = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingRole, setEditingRole] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [search, setSearch] = useState('');
    const api = roleService;
    const { ctxHolder, onSuccess, onError } = useMessage();
    const debValue = useDebounce(search.trim(), 800);
    const refreshTable = async (pageToFetch = currentPage) => {
        try {
            const response = await getRoles(pageToFetch, pageSize, debValue);
            setTotal(response.result.total);

            if (response.result.data.length > 0) {
                setData(response.result.data);
            } else if (pageToFetch > 1) {
                return refreshTable(pageToFetch - 1);
            }
        } catch {
            onError('Error fetching roles:');
        }
    };

    const handleDelete = async (id: string) => {
        await api.deleteRole(id);
        await refreshTable();
        await onSuccess('The role has been successfully deleted.');
    };

    const handleEdit = async (id: string) => {
        const response = await api.getRoleById(id);
        setEditingRole(response.result);
        setIsModalOpen(true);
    };
    const handleAddNew = () => {
        setEditingRole(null);
        setIsModalOpen(true);
    };
    const columns = useGetRoleCols(handleEdit, handleDelete);
    const onPaginationChange: PaginationProps['onChange'] = (current, pageSize) => {
        setCurrentPage(current);
        setPageSize(pageSize);
    };

    useEffect(() => {
        setTableLoading(true);
        refreshTable().finally(() => setTableLoading(false));
    }, [pageSize, currentPage, debValue]);

    return (
        <>
            {ctxHolder}
            <div className="group-wrapper">
                <Button
                    size="large"
                    icon={<FileAddOutlined />}
                    className={'success-btn mb-15p mobile-full-width'}
                    onClick={handleAddNew}
                >
                    Add New Role
                </Button>
                <Input
                    className="search-input"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>

            <Table
                bordered
                scroll={{ x: 400 }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={tableLoading}
            />

            <Pagination
                align="end"
                className="mt-15p"
                defaultCurrent={currentPage}
                onChange={onPaginationChange}
                total={total}
            />
            <RolesModal
                onSuccess={refreshTable}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setEditingRole(null);
                }}
                editingRole={editingRole}
            />
        </>
    );
};

import './group.css';

import { Button, Input, Pagination, PaginationProps, Table } from 'antd';
import { getGroups, useGetCols } from './helpers';
import { useEffect, useState } from 'react';

import { FileAddOutlined } from '@ant-design/icons';
import { GroupModal } from './components/GroupModal';
import PopConfirmApp from '../../components/button/PopConfirmApp';
import groupService from '../../services/api/api-services/groupService';
import { useDebounce } from '../../hooks/useDebounce';

export const Group = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [editingGroup, setEditingGroup] = useState<any>(null);
    const [data, setData] = useState<any[]>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [tableLoading, setTableLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [pageSize, setPageSize] = useState(10);
    const api = groupService;

    const debValue = useDebounce(search.trim(), 800);
    const handleEdit = async (id: any) => {
        const response = await api.getGroupById(id);
        setEditingGroup(response.result);
        setIsModalOpen(true);
    };
    const refreshTable = async () => {
        return getGroups(currentPage, pageSize, debValue).then((response) => {
            setTotal(response?.result.total);
            setData(response?.result.data);
        });
    };

    const handleDelete = async (id: string) => {
        await api.deleteGroup(id);
        refreshTable();
    };

    const handleAddNew = () => {
        setEditingGroup(null);
        setIsModalOpen(true);
    };
    const columns = useGetCols(handleEdit, handleDelete);
    const onPaginationChange: PaginationProps['onChange'] = (current, pageSize) => {
        setCurrentPage(current);
        setPageSize(pageSize);
    };
    useEffect(() => {
        setTableLoading(true);
        refreshTable().finally(() => setTableLoading(false));
    }, [currentPage, pageSize, debValue]);

    return (
        <>
            <div className="group-wrapper">
                <Button
                    size="large"
                    icon={<FileAddOutlined />}
                    className={'success-btn mobile-full-width'}
                    onClick={handleAddNew}
                >
                    Add New Group
                </Button>
                <Input
                    className="search-input"
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setSearch(e.target.value)}
                />
            </div>
            <Table
                bordered
                scroll={{ x: 400 }}
                columns={columns}
                dataSource={data}
                pagination={false}
                loading={tableLoading}
            />

            <Pagination
                align="end"
                className="mt-15p"
                defaultCurrent={currentPage}
                onChange={onPaginationChange}
                showSizeChanger
                total={total}
            />
            <GroupModal
                onSuccess={refreshTable}
                isOpen={isModalOpen}
                onClose={() => {
                    setIsModalOpen(false);
                    setEditingGroup(null);
                }}
                editingGroup={editingGroup}
            />
        </>
    );
};

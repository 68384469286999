import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { Suspense, lazy, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from './app/hooks';

import MainLayout from './components/layout/MainLayout';
import NotFoundPage from './components/layout/result/NotFoundPage';
import { Spin } from 'antd';
import { setLogged } from './redux-toolkit';
import { Group } from './pages/group/Group';
import { Role } from './pages/roles/Roles';

const Users = lazy(() => import('./pages/users/Users'));
const SignIn = lazy(() => import('./pages/signin/SignIn'));
const Practices = lazy(() => import('./pages/practices/Practices'));
const Dashboard = lazy(() => import('./pages/dashboard/Dashboard'));
const NewPractice = lazy(() => import('./pages/practices/new-practice/NewPractice'));
const PracticeDetails = lazy(() => import('./pages/practices/details/PracticeDetails'));

function App() {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { isLogged } = useAppSelector((state) => state.global);
    const hasNotAuth = !isLogged && sessionStorage.getItem('auth') !== null;

    useEffect(() => {
        if (hasNotAuth) {
            dispatch(setLogged(true));
        } else if (!isLogged && location.pathname !== '/sign-in') {
            navigate(`/sign-in?redirect=${encodeURIComponent(location.pathname + location.search)}`, { replace: true });
        }
        if (isLogged) {
            const params = new URLSearchParams(location.search);
            const redirectUrl = params.get('redirect');
            if (redirectUrl) {
                navigate(decodeURIComponent(redirectUrl));
            }
        }
    }, [hasNotAuth, isLogged, location, navigate, dispatch]);

    return (
        <Suspense fallback={<Spin spinning={true} style={{ display: 'none' }}></Spin>}>
            {isLogged && (
                <MainLayout
                    children={
                        <>
                            <Routes>
                                <Route path="/" element={<Dashboard />} />
                                <Route path="/dashboard" element={<Dashboard />} />
                                <Route path="/users" element={<Users />} />
                                <Route path="/practices" element={<Practices />} />
                                <Route path="/group" element={<Group />} />
                                <Route path="/role" element={<Role />} />
                                <Route path="/new-practice" element={<NewPractice />} />
                                <Route path={'/practices/details/?id=:id?/*'} element={<PracticeDetails />} />
                                <Route path={'/practices/details/update?id=:id?/*'} element={<PracticeDetails />} />
                                <Route path="*" element={<NotFoundPage />} />
                            </Routes>
                        </>
                    }
                />
            )}
            {!isLogged && (
                <Routes>
                    <Route path={'/sign-in'} element={<SignIn />} />
                    <Route path="*" element={<SignIn />} />
                </Routes>
            )}
        </Suspense>
    );
}

export default App;

import { IGroupParams, IGroupsResponse } from '.';

import { BaseService } from '../baseService';

class GroupService extends BaseService<any, IGroupsResponse> {
    constructor() {
        super('/groups');
    }

    async getGroups(data: IGroupParams) {
        try {
            const response = await this.get(data);
            return response;
        } catch (error) {}
    }
    async deleteGroup(id: string) {
        const response = await this.delete(id);
        return response;
    }
    async createGroup(data: any) {
        const response = await this.create(data);
        return response;
    }
    async getGroupById(id: string) {
        const response = await this.getById(id);
        return response;
    }
    async updateGroup(id: string, data: any) {
        const response = await this.update(id, data);
        return response;
    }
}

export default new GroupService();
